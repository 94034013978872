body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', sans-serif;
}

:root {
  --primary-color: #FFE300;
  --secondary-color: #fff;
  --error-color: #FF1C3C;
  --success-color: #1BBC9B;
  --dark-color: #888;
  --light-dark-color: #959ba2;
}