.input-field.ip-field-container {
  &.askoheat-bottom {
    margin-bottom: 50px !important;
  }

  .ip-field {
    width: 100%;
  }

  .identify-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 20px;

    .identify-btn {
      margin-bottom: 20px !important;
      min-width: 109px;
      height: 23px;
      line-height: 0.1 !important;
    }
  }
}
.ocppCharderField,
.skiField {
  position: relative;

  button.searchChargersBtn.btn.m-btn--pill.m-btn--air.btn-secondary {
    position: absolute;
    padding: 6px 32px !important;
    min-width: unset !important;
    width: fit-content !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
    top: 15px;
    right: 27px;
    border: 2px solid #fff !important;
    color: #fff;
    font-size: 14px !important;
    font-weight: 700 !important;
    background-color: transparent !important;

    &:hover:hover:not(:disabled):not(.active) {
      background-color: #fff !important;
      color: #000 !important;
    }
  }

  
}
